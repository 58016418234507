import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  SECTION_MEMBER_POSTS,
  LAYOUT_NAME_PG_SIDE_BY_SIDE,
  LAYOUT_PG_SIDE_BY_SIDE,
} from '@wix/communities-blog-client-common';
import { connect } from '../../../common/components/runtime-context';
import withPermissions from '../../../common/hoc/with-permissions';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import Pagination from '../../../common/containers/pagination/pagination';
import { getMemberPosts } from '../../../common/selectors/post-selectors';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';
import { getPostCount } from '../../../common/store/post-count/post-count-selector';
import AnimatedLoader from '../../../common/components/animated-loader';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { MEMBER_POSTS_ENTITY } from '../../store/initial-data/fetch-initial-data';
import { MEMBER_POSTS_PAGE_SIZE } from '../../constants/member-posts-page';
import NoMemberPosts from '../no-member-posts';
import styles from './member-posts.scss';

class MemberPosts extends React.Component {
  state = { page: 1 };

  handlePageClick = ({ page }) => {
    this.setState({ page });
    this.props.fetchPageData(page);
  };

  render() {
    const { posts, totalPostCount, componentId, canSeeMoreButton, isLoading } = this.props;
    const hasPosts = Boolean(posts.length);

    return (
      <AnimatedLoader isLoading={isLoading}>
        {hasPosts ? (
          <>
            <PostListProGallery
              layoutName={LAYOUT_NAME_PG_SIDE_BY_SIDE}
              layoutType={LAYOUT_PG_SIDE_BY_SIDE}
              section={SECTION_MEMBER_POSTS}
              domId={componentId}
              allPosts={posts}
              canSeeMoreButton={canSeeMoreButton}
              isLoading={isLoading}
            />
            <Pagination
              useDescriptionColor={true}
              page={this.state.page}
              onChange={this.handlePageClick}
              pageSize={MEMBER_POSTS_PAGE_SIZE}
              section={SECTION_MEMBER_POSTS}
              entityCount={totalPostCount}
              createPageUrl={() => {}}
              activeClass="blog-member-posts-pagination-active"
              containerClassName={styles.paginationContainer}
            />
          </>
        ) : (
          <NoMemberPosts />
        )}
      </AnimatedLoader>
    );
  }
}

MemberPosts.propTypes = {
  postCount: PropTypes.number.isRequired,
  posts: PropTypes.array.isRequired,
  componentId: PropTypes.string.isRequired,
  fetchPageData: PropTypes.func.isRequired,
  canSeeMoreButton: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  return {
    totalPostCount: getPostCount(state),
    posts: getMemberPosts(state),
    componentId: host.id,
    fetchPageData: actions.fetchMemberPostsPageDataPromisified,
    canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
    isLoading: getIsEntityLoading(state, MEMBER_POSTS_ENTITY),
  };
};

export default flowRight(withPermissions, connect(mapRuntimeToProps))(MemberPosts);
