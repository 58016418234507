import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../../../common/components/empty-states';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/hoc/with-translate';
import {
  MEMBER_POSTS_PAGE_TAB_DRAFTS,
  MEMBER_POSTS_PAGE_TAB_PUBLICATIONS,
  MEMBER_POSTS_PAGE_TAB_SUBMISSIONS,
} from '../../constants/member-posts-page';
import { getActiveTab } from '../../store/active-tab/active-tab-selectors';
import styles from './no-member-posts.scss';

class NoMemberPosts extends React.Component {
  styleProps = {
    style: { width: 'auto', margin: '30px 0px 0px 0px' },
    className: styles.root,
  };

  render() {
    const { activeTab, t } = this.props;

    if (activeTab === MEMBER_POSTS_PAGE_TAB_PUBLICATIONS) {
      return (
        <EmptyStates title={t('no-publications.title')} content={t('no-publications.content')} {...this.styleProps} />
      );
    } else if (activeTab === MEMBER_POSTS_PAGE_TAB_SUBMISSIONS) {
      return (
        <EmptyStates title={t('no-submissions.title')} content={t('no-submissions.content')} {...this.styleProps} />
      );
    } else if (activeTab === MEMBER_POSTS_PAGE_TAB_DRAFTS) {
      return <EmptyStates title={t('no-drafts.title')} content={t('no-drafts.content')} {...this.styleProps} />;
    }
  }
}

NoMemberPosts.propTypes = {
  t: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state) => ({
  activeTab: getActiveTab(state),
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(NoMemberPosts);
