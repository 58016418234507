import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, ALIGNMENT } from 'wix-ui-tpa/Tabs';
import { connect } from '../../../../common/components/runtime-context';
import withTranslate from '../../../../common/hoc/with-translate';
import { getActiveTab } from '../../../store/active-tab/active-tab-selectors';
import {
  MEMBER_POSTS_PAGE_TAB_DRAFTS,
  MEMBER_POSTS_PAGE_TAB_PUBLICATIONS,
  MEMBER_POSTS_PAGE_TAB_SUBMISSIONS,
} from '../../../constants/member-posts-page';
import { classes } from './page-tabs.st.css';

const TAB_BY_TAB_INDEX = [
  MEMBER_POSTS_PAGE_TAB_PUBLICATIONS,
  MEMBER_POSTS_PAGE_TAB_SUBMISSIONS,
  MEMBER_POSTS_PAGE_TAB_DRAFTS,
];

class PageTabs extends React.Component {
  handleTabClick = async (tabIndex) => {
    const tab = TAB_BY_TAB_INDEX[tabIndex];
    if (tab) {
      await this.props.setActiveTab(tab);
      await this.props.fetchPageData();
    }
  };

  render() {
    const { t, activeTab } = this.props;
    return (
      <Tabs
        className={classes.root}
        items={[{ title: t('tabs.publications') }, { title: t('tabs.submissions') }, { title: t('tabs.drafts') }]}
        activeTabIndex={TAB_BY_TAB_INDEX.indexOf(activeTab)}
        onTabClick={this.handleTabClick}
        alignment={ALIGNMENT.left}
      />
    );
  }
}

PageTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  fetchPageData: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  activeTab: getActiveTab(state),
  setActiveTab: actions.setActiveTabPromisified,
  fetchPageData: actions.fetchMemberPostsPageDataPromisified,
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(PageTabs);
